import { useEffect } from 'react'

const usePreventScroll = (preventScroll: boolean): void => {
  useEffect(() => {
    const rootElement = document.getElementById('root')

    if (rootElement && preventScroll) {
      // add overflow-hidden to prevent scrolling
      rootElement.style.overflow = 'hidden'
    } else if (rootElement) {
      // add overflow-auto to allow scrolling
      rootElement.style.overflow = 'auto'
    }
  }, [preventScroll])
}

export default usePreventScroll
