import { yupResolver } from '@hookform/resolvers/yup'
import classNames from 'classnames'
import { useRouter } from 'next/navigation'
import { useState, useContext } from 'react'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'

import type { ReactElement } from 'react'
import type { SubmitHandler } from 'react-hook-form'
import type { IApiError } from '~/types/api'

import Modal from '..'

import Button, { VARIANT } from '~/components/Button'
import Header from '~/components/Header'
import TextInput from '~/components/Inputs/TextInput'
import { ModalContext, MODAL_ACTIONS } from '~/contexts/ModalContext'
import { useUpdatePasswordMutation } from '~/queries/authentication'
import { displayToast } from '~/utils/toast'
import { YupPassword } from '~/utils/yup'

import './styles.scss'

export interface Props {
  className?: string
  isOpen: boolean
  onConfirm?: VoidFunction
  shouldCloseOnOverlayClick?: boolean
}

interface IFormInput {
  newPassword: string
  oldPassword: string
}

const validationSchema = Yup.object({
  newPassword: YupPassword.required('This field is required'),
  oldPassword: Yup.string().required('This field is required'),
})

const UpdatePasswordModal = ({ className, isOpen, shouldCloseOnOverlayClick }: Props): ReactElement => {
  /** Local state */

  const { dispatch } = useContext(ModalContext)

  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setError,
  } = useForm<IFormInput>({ mode: 'onSubmit', resolver: yupResolver(validationSchema) })

  const router = useRouter()

  const [isLoading, setIsLoading] = useState(false)

  const classes = classNames('update-password-modal', className)

  /** Queries & Mutations */

  const { mutateAsync: updatePasswordMutation } = useUpdatePasswordMutation()

  /** Handlers */

  const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput) => {
    const { newPassword, oldPassword } = data

    setIsLoading(true)

    try {
      await updatePasswordMutation({ oldPassword, password: newPassword })
      displayToast('Your password was successfully updated', 'success', true)
      router.push('/account')
    } catch (e) {
      const response = (e as IApiError).response

      switch (response?.data?.kind) {
        case 'invalid_credentials':
          setError('oldPassword', { message: 'Old password is invalid' })
          break
        case 'password_no_special':
          setError('newPassword', {
            message:
              'Password requires at least a lowercase letter and either an uppercase letter, a special character or a number',
          })
          break
        case 'password_no_letter':
          setError('newPassword', { message: 'Password requires at least one lowercase letter' })
          break
        default:
          setError('newPassword', { message: 'Something wrong happened, please try again' })
      }
    } finally {
      setIsLoading(false)
    }
  }

  const handleClose = () => {
    dispatch({
      type: MODAL_ACTIONS.UPDATE_PASSWORD_CLOSE,
    })
    reset()
  }

  return (
    <Modal
      className={classes}
      isOpen={isOpen}
      onClose={handleClose}
      portalClassName="update-password-portal"
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    >
      <Header subtitle="Enter your new password" title="Update password" />
      <form className="update-password-modal__form" onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          placeholder="Old password"
          type="password"
          {...register('oldPassword')}
          error={errors.oldPassword?.message}
        />
        <TextInput
          placeholder="New password"
          type="password"
          {...register('newPassword')}
          error={errors.newPassword?.message}
        />
        <div className="update-password-modal__form__actions">
          <Button disabled={isLoading} onClick={handleClose} outline type="button" variant={VARIANT.SECONDARY}>
            Cancel
          </Button>
          <Button isLoading={isLoading} type="submit">
            Continue
          </Button>
        </div>
      </form>
    </Modal>
  )
}

export default UpdatePasswordModal
