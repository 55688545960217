import classNames from 'classnames'
import Image from 'next/image'

import type { ReactElement, ReactNode } from 'react'

import './styles.scss'

interface Props {
  className?: string
  content: ReactNode
  withIcon?: boolean
}

export const toastLinkClassName = 'toast-component__message__link'

const Toast = ({ className, content, withIcon }: Props): ReactElement => {
  const toastClasses = classNames('toast-component', className)

  return (
    <div className={toastClasses}>
      <span className="toast-component__message">{content}</span>
      {withIcon && (
        <Image
          alt=""
          className="toast-component__icon"
          height={26}
          priority
          src="/medias/icon-check-circle.png"
          width={26}
        />
      )}
    </div>
  )
}

export default Toast
