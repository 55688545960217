import Cookies from 'universal-cookie'

import type { IAccountState } from '~/types/user'

import config from '~/config'
import { APP_KEY, COOKIES } from '~/const'
import { request } from '~/utils/api'

const cookies = new Cookies()

export interface IAuthPayload {
  password: string
}

export interface IRequestConfirmationMail {
  email: string
  redirect: string
}

const DEFAULT_PROPS = {
  app_key: APP_KEY,
  device_language: 'en',
  device_type: 'web',
}

export const login = (payload: IAuthPayload & { email: string }): Promise<IAccountState> => {
  const installationId = cookies.get(COOKIES.INSTALLATION_ID)

  return request(
    {
      data: { ...DEFAULT_PROPS, installation_id: installationId, ...payload },
      method: 'POST',
      url: `${config.MWM_AUTH_API_URL}/mail/login`,
    },
    false
  )
}

export const register = (payload: IAuthPayload & { email: string }): Promise<IAccountState> => {
  const installationId = cookies.get(COOKIES.INSTALLATION_ID)

  return request(
    {
      data: { ...DEFAULT_PROPS, access_token: '', installation_id: installationId, ...payload },
      method: 'POST',
      url: `${config.MWM_AUTH_API_URL}/mail/register`,
    },
    false
  )
}

export interface IRequestResetPasswordPayload {
  email: string
}

export const requestResetPassword = ({ email }: IRequestResetPasswordPayload): Promise<void> => {
  return request(
    {
      data: {
        app_key: APP_KEY,
        email,
        redirect: location.origin,
      },
      method: 'POST',
      url: `${config.MWM_AUTH_API_URL}/mail/reset-password/request`,
    },
    false
  )
}

export const resetPassword = (payload: IAuthPayload & { code: string }): Promise<IAccountState> => {
  const installationId = cookies.get(COOKIES.INSTALLATION_ID)

  return request(
    {
      data: { ...DEFAULT_PROPS, installation_id: installationId, ...payload },
      method: 'POST',
      url: `${config.MWM_AUTH_API_URL}/mail/reset-password/submit`,
    },
    false
  )
}

export const requestConfirmationMail = (payload: IRequestConfirmationMail): Promise<void> => {
  return request(
    {
      data: {
        app_key: APP_KEY,
        ...payload,
      },
      method: 'POST',
      url: `${config.MWM_AUTH_API_URL}/mail/confirmation/request`,
    },
    false
  )
}

export const deleteAccount = (): Promise<void> => {
  return request({
    method: 'DELETE',
    url: `${config.MWM_AUTH_API_URL}/delete`,
  })
}

export const updatePassword = (payload: IAuthPayload & { oldPassword: string }): Promise<void> => {
  const installationId = cookies.get(COOKIES.INSTALLATION_ID)

  const { oldPassword, password } = payload

  return request({
    data: {
      ...DEFAULT_PROPS,
      app_key: APP_KEY,
      installation_id: installationId,
      old_password: oldPassword,
      password,
    },
    method: 'PUT',
    url: `${config.MWM_AUTH_API_URL}/mail/password`,
  })
}
