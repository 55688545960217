import classNames from 'classnames'

import type { ReactElement, ReactNode } from 'react'

interface Props {
  children: ReactNode
  className?: string
  id?: string
  required?: boolean
}

const Label = ({ children, className, id, required }: Props): ReactElement => {
  return (
    <label className={classNames('label-component', className)} htmlFor={id}>
      {children} {required && <span className="mandatory-star">*</span>}
    </label>
  )
}

export default Label
