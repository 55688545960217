'use client'

import { createContext, useReducer } from 'react'

import type { ReactElement, Dispatch, PropsWithChildren } from 'react'
import type { Props as DialogModalProps } from '~/components/Modal/DialogModal'

import DialogModal from '~/components/Modal/DialogModal'
import UpdatePasswordModal from '~/components/Modal/UpdatePasswordModal'

export enum MODAL_ACTIONS {
  DIALOG_MODAL_CLOSE = 'dialogModalClose',
  DIALOG_MODAL_OPEN = 'dialogModalOpen',
  UPDATE_PASSWORD_CLOSE = 'updatePasswordOpen',
  UPDATE_PASSWORD_OPEN = 'updatePasswordClose',
}

type ModalType = 'dialog' | 'updatePassword'

interface IState extends Record<ModalType, boolean> {
  options?: Omit<DialogModalProps, 'isOpen'>
  params?: { app: string }
}

export interface IModalAction {
  params?: { app: string }
  payload?: Omit<DialogModalProps, 'isOpen'>
  type: MODAL_ACTIONS
}

export const modalReducerInitialState: IState = {
  dialog: false,
  options: undefined,
  params: { app: 'tech_platform' },
  updatePassword: false,
}

export const modalReducer = (state: IState, action: IModalAction): IState => {
  switch (action.type) {
    case MODAL_ACTIONS.DIALOG_MODAL_CLOSE:
      return {
        ...state,
        dialog: false,
      }
    case MODAL_ACTIONS.DIALOG_MODAL_OPEN:
      return {
        ...state,
        dialog: true,
        options: action.payload,
      }
    case MODAL_ACTIONS.UPDATE_PASSWORD_CLOSE:
      return {
        ...state,
        updatePassword: false,
      }
    case MODAL_ACTIONS.UPDATE_PASSWORD_OPEN:
      return {
        ...state,
        params: action.params,
        updatePassword: true,
      }
    default:
      return state
  }
}

export const ModalContext = createContext<{ dispatch: Dispatch<IModalAction>; state: IState }>({
  dispatch: () => {
    // NOTE: nothing here.
  },
  state: modalReducerInitialState,
})

const ModalContextProvider = ({ children }: PropsWithChildren): ReactElement => {
  const [state, dispatch] = useReducer(modalReducer, modalReducerInitialState)

  return (
    <ModalContext.Provider value={{ dispatch, state }}>
      <DialogModal {...state.options} isOpen={state.dialog} />
      <UpdatePasswordModal isOpen={state.updatePassword} />
      {children}
    </ModalContext.Provider>
  )
}

export default ModalContextProvider
