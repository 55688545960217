import classNames from 'classnames'
import ReactModal from 'react-modal'

import type { PropsWithChildren, ReactElement } from 'react'

import usePreventScroll from '~/hooks/usePreventScroll'

import './styles.scss'

interface Props {
  className?: string
  contentRef?: (instance: HTMLDivElement) => void
  isOpen: boolean
  onClose: VoidFunction
  overlayRef?: (instance: HTMLDivElement) => void
  portalClassName?: string
  shouldCloseOnOverlayClick?: boolean
  timeoutToClose?: number
}

const Modal = ({
  children,
  className,
  contentRef,
  isOpen,
  onClose,
  overlayRef,
  portalClassName,
  shouldCloseOnOverlayClick = true,
  timeoutToClose = 0,
}: PropsWithChildren<Props>): ReactElement => {
  /** Local state */

  const portalClasses = classNames('modal-component-portal', portalClassName)
  const modalClasses = classNames('modal-component-container', className)

  /** Effects */

  usePreventScroll(isOpen)

  return (
    <ReactModal
      ariaHideApp={false}
      className={modalClasses}
      closeTimeoutMS={timeoutToClose}
      contentRef={contentRef}
      isOpen={isOpen}
      onRequestClose={onClose}
      overlayRef={overlayRef}
      portalClassName={portalClasses}
      shouldCloseOnEsc={shouldCloseOnOverlayClick}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      shouldFocusAfterRender={false}
    >
      {children}
    </ReactModal>
  )
}

export default Modal
