import Image from 'next/image'

import type { ReactElement, ReactNode } from 'react'

import './styles.scss'

interface Props {
  logo?: string
  subtitle?: ReactNode
  title?: string
}

export const emphasizedSubtitleClassName = 'header-component__subtitle--emphasized'

const Header = ({ logo, subtitle, title }: Props): ReactElement => {
  return (
    <div className="header-component">
      {logo ? (
        <Image
          alt="Logo"
          className="header__logo"
          height={50}
          priority
          src={logo}
          style={{ borderRadius: '1rem' }}
          width={50}
        />
      ) : null}
      <div className="header-component__title">{title}</div>
      <div className="header-component__subtitle">{subtitle}</div>
    </div>
  )
}

export default Header
