import classNames from 'classnames'
import Image from 'next/image'
import { useEffect, useState } from 'react'

import type { ElementType, ReactElement, ReactNode } from 'react'

import Label from '~/components/Label'

import './styles.scss'

export interface Props {
  active?: boolean
  children: ReactNode
  className?: string
  disabled?: boolean
  error?: ReactNode
  inputId?: string
  inputValue?: string
  label?: ReactNode
  leftIcon?: ElementType
  onRightIconClick?: () => void
  required?: boolean
  rightIcon?: ElementType
  validator?: (value: string) => boolean
}

const InputWrapper = ({
  active,
  children,
  className,
  disabled,
  error,
  inputId,
  inputValue,
  label,
  leftIcon: LeftIcon,
  onRightIconClick,
  required,
  rightIcon: RightIcon,
  validator,
}: Props): ReactElement => {
  const [isValid, setIsValid] = useState(false)

  useEffect(() => {
    inputValue && validator && setIsValid(validator(inputValue))
  }, [validator, inputValue])

  const parentClasses = classNames('input-wrapper-component', className, {
    active,
    'input-wrapper-component--error': (!!validator && !!inputValue && !isValid) || (!validator && !!error),
    'input-wrapper-component--valid': !!validator && !!inputValue && isValid,
    'with-icon': !!LeftIcon,
  })

  const inputClasses = classNames('input-wrapper-component__input', { disabled })

  return (
    <div className={parentClasses}>
      {label ? (
        <Label className="input-wrapper-component__label" id={inputId} required={required}>
          {label}
        </Label>
      ) : null}
      <div className={inputClasses}>
        {LeftIcon ? (
          <div className="icon-wrapper left-icon">
            <LeftIcon className="icon" />
          </div>
        ) : null}
        {children}
        <div className="icon-wrapper">
          {(!!validator && !!inputValue) || (!validator && !!error) ? (
            <div className="icon-wrapper">
              <Image
                alt={''}
                className="icon validator-icon"
                height={24}
                src={isValid ? '/medias/text-input-valid-icon.svg' : '/medias/text-input-error-icon.svg'}
                width={24}
              />
            </div>
          ) : null}
          {RightIcon ? (
            <div className="icon-wrapper right-icon">
              <RightIcon className="icon" onClick={onRightIconClick} />
            </div>
          ) : null}
        </div>
      </div>
      {(!!validator && !!inputValue && !isValid) || (!validator && !!error) ? (
        <span className="input-wrapper-component__error">{error}</span>
      ) : null}
    </div>
  )
}

export default InputWrapper
