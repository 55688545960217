import { toast } from 'react-toastify'

import type { ReactNode } from 'react'
import type { Id, ToastOptions, TypeOptions } from 'react-toastify'

import Toast from '~/components/Toast'

export const displayToast = (message: ReactNode, type: TypeOptions, withIcon?: boolean): Id => {
  const toastOptions: ToastOptions = {
    autoClose: 0,
    closeOnClick: true,
    hideProgressBar: true,
    pauseOnHover: true,
    position: 'bottom-center',
    progress: undefined,
    rtl: false,
    type: type,
  }

  return toast(<Toast content={message} withIcon={withIcon} />, toastOptions)
}
