import classNames from 'classnames'

import type { ReactElement, ReactNode } from 'react'

import Modal from '..'

import Button, { VARIANT } from '~/components/Button'
import Header from '~/components/Header'

import './styles.scss'

export interface Props {
  btnCancelText?: string
  btnConfirm?: ReactNode
  btnConfirmText?: string
  className?: string
  iconSrc?: string
  isOpen: boolean
  mainText?: string
  onClose?: VoidFunction
  onConfirm?: VoidFunction
  shouldCloseOnOverlayClick?: boolean
  subText?: string
}

const DialogModal = ({
  btnCancelText,
  btnConfirm,
  btnConfirmText,
  className,
  iconSrc = '',
  isOpen,
  mainText,
  onClose = () => null,
  onConfirm = () => null,
  shouldCloseOnOverlayClick,
  subText,
}: Props): ReactElement => {
  /** Local state */

  const classes = classNames('dialog-modal', className)

  return (
    <Modal
      className={classes}
      isOpen={isOpen}
      onClose={onClose}
      portalClassName="dialog-portal"
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    >
      <div className="dialog-modal__main">
        <Header logo={iconSrc} subtitle={subText} title={mainText} />
        <div className="dialog-modal__main__actions">
          <Button onClick={onClose}>{btnCancelText ? btnCancelText : 'Cancel'}</Button>
          {btnConfirm ? (
            btnConfirm
          ) : (
            <Button onClick={onConfirm} outline variant={VARIANT.SECONDARY}>
              {btnConfirmText ? btnConfirmText : 'Confirm'}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default DialogModal
