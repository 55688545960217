'use client'

import { useMutation, type UseMutationResult } from 'react-query'

import type { IAuthPayload, IRequestConfirmationMail, IRequestResetPasswordPayload } from '~/api/authentication'
import type { IAccountState } from '~/types/user'

import {
  deleteAccount,
  login,
  register,
  resetPassword,
  requestConfirmationMail,
  updatePassword,
  requestResetPassword,
} from '~/api/authentication'

////////// MUTATIONS //////////

export const useLoginMutation = (): UseMutationResult<
  IAccountState,
  unknown,
  IAuthPayload & {
    email: string
  }
> => {
  return useMutation(login)
}

export const useRegisterMutation = (): UseMutationResult<
  IAccountState,
  unknown,
  IAuthPayload & {
    email: string
  }
> => {
  return useMutation(register)
}

export const useRequestResetPasswordMutation = (): UseMutationResult<void, unknown, IRequestResetPasswordPayload> => {
  return useMutation(requestResetPassword)
}

export const useResetPasswordMutation = (): UseMutationResult<
  IAccountState,
  unknown,
  IAuthPayload & { code: string }
> => {
  return useMutation(resetPassword)
}

export const useRequestConfirmationMail = (): UseMutationResult<void, unknown, IRequestConfirmationMail> => {
  return useMutation(requestConfirmationMail)
}

export const useDeleteAccountMutation = (): UseMutationResult<void, unknown, Record<string, never>> => {
  return useMutation(deleteAccount)
}

export const useUpdatePasswordMutation = (): UseMutationResult<
  void,
  unknown,
  IAuthPayload & { oldPassword: string }
> => {
  return useMutation(updatePassword)
}
