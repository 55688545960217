import Image from 'next/image'
import { forwardRef, useMemo, useState } from 'react'

import type { InputHTMLAttributes, ElementType, ChangeEvent, FocusEvent, ReactNode } from 'react'

import InputWrapper from '../InputWrapper'

import { createUUID } from '~/utils/uuid'

import './styles.scss'

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  className?: string
  clearable?: boolean
  error?: ReactNode
  label?: string
  leftIcon?: ElementType
  onClear?: () => void
  onRightIconClick?: () => void
  rightIcon?: ElementType
  validator?: (value: string) => boolean
  value?: string
}

const TextInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      className,
      clearable,
      error,
      label,
      leftIcon: LeftIcon,
      onClear,
      onRightIconClick,
      rightIcon: RightIcon,
      validator,
      value = '',
      ...props
    },
    ref
  ) => {
    const [active, setActive] = useState(false)

    const [inputValue, setInputValue] = useState(value)

    const id = useMemo(() => createUUID(), [])

    const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
      setActive(true)
      props.onFocus && props.onFocus(e)
    }

    const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
      !inputValue && setActive(false)
      props.onBlur && props.onBlur(e)
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.currentTarget.value)
      props.onChange && props.onChange(e)
    }

    const clearInput = () => {
      setInputValue('')
      onClear && onClear()
    }

    return (
      <InputWrapper
        active={active || !!inputValue}
        className={className}
        disabled={props.disabled}
        error={error}
        inputId={id}
        inputValue={inputValue}
        label={label}
        leftIcon={LeftIcon}
        onRightIconClick={onRightIconClick}
        required={props.required}
        rightIcon={RightIcon}
        validator={validator}
      >
        <input
          {...props}
          className="text-input"
          id={id}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          ref={ref}
          value={inputValue}
        />
        {clearable && !!inputValue ? (
          <Image
            alt="Clear input"
            className="button-clear-input"
            height={19}
            onClick={clearInput}
            src="/medias/cross-icon-white.svg"
            width={19}
          />
        ) : null}
      </InputWrapper>
    )
  }
)

TextInput.displayName = 'TextInput'

export default TextInput
